import React from "react";
import '../App.css';
import './ServicesSection.css';


function AdvantageSection() {
    return (
        <div className="hero-container">
            <img src = '/photos/images/why WY hero.png' />
            <div className="banner-content" style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <h1 className="banner-title" style={{ color: 'white', width: '60%' }}>Why Dunham</h1>
                <br />
                <p className="banner-paragraph" style={{ color: 'white', width: '60%' }}>
                    Dunham Private Trust is the Wyoming division of Dunham Trust Company. 
                    Dunham Trust Company opened in 1999 with the core philosophy that 
                    cultivating highly personal service will make a positive difference in our 
                    commitment to provide exceptional trust administration services.
                </p>
                <br />
                {/* <Link to="/about">
                    <button className="banner-button">Secure Your Legacy</button>
                </Link> */}
            </div>
        </div>
    );
}

export default AdvantageSection;