import React from 'react';
import './TeamSection.css';

function TeamSection() {
    return (
        <div className="team-container11">
            <div className="text-container11">
                <h1>The Dunham Difference</h1>
                <p className='bold'>
                Dunham Trust Company opened in 1999 with the core philosophy that cultivating 
                highly personal service will make a positive difference in our commitment to 
                provide exceptional trust administration services.
                </p>
                <br/>
                <p>
                Since then, Dunham has helped thousands of families realize their aspiration to 
                live more fully by safeguarding their life’s work in stateside havens. Our clients and 
                their advisors build on Dunham’s open architecture and modular approach to planning wherein 
                our client decides who will manage their investments. Our firm remains agnostic about who 
                ultimately invests on behalf of our clients. Our role is to provide a concierge 
                administrative offering and stewardship to ensure complex assets including 
                closely-held business interests, private credit, captives, real estate and 
                philanthropic entities are preserved for present and future generations.
                </p>
            </div>
            <div className="image-container11">
                <img src='/photos/images/who we are_hiking.png' alt="The Team" />
            </div>
        </div>
    );
}

export default TeamSection;
