import React, { useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import './ContactItem.css'; // Updated to ContactItem.css

const ContactItem = () => {
  const [captchaValue, setCaptchaValue] = useState(null);

  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    console.log("Captcha value:", value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!captchaValue) {
      alert("Please complete the reCAPTCHA");
      return;
    }

    // Proceed with form submission
    console.log("Form submitted with reCAPTCHA token:", captchaValue);
  };

  return (
    <div className="contact-page">
      <div className="contact-info">
        <h1 className="contact-title">Get in Touch</h1>
        <div className="underline"></div>
        <h3>Contact</h3>
        <p>Toll-Free: (800) 555-5555</p>
        <p>Sales: (555) 555-5555</p>
        <p>Phone: (555) 555-5555</p>
        <p>Fax: (555) 555-5555</p>
      </div>

      <div className="contact-form">
        <form onSubmit={handleSubmit}>
          <div className="form-section">
            <h3>Personal Information</h3>
            <div className="input-group">
              <input type="text" placeholder="First Name" className="input-field" required />
              <input type="text" placeholder="Last Name" className="input-field" required />
            </div>
            <div className="input-group">
              <input type="email" placeholder="Email Address" className="input-field" required />
              <input type="tel" placeholder="Phone Number" className="input-field" required />
            </div>
            <input type="text" placeholder="Account # or Title" className="input-full" required />
          </div>

          <div className="form-section">
            <h3>Suggested Questions</h3>
            <select className="input-full" required>
              <option value="">Select a question...</option>
              <option value="services">What services do you offer?</option>
              <option value="consultation">How can I schedule a consultation?</option>
              <option value="pricing">What is your pricing model?</option>
            </select>
          </div>

          <div className="form-section">
            <h3>Additional Information</h3>
            <textarea placeholder="Additional information" className="textarea"></textarea>
          </div>

          <div className="recaptcha">
            <ReCAPTCHA
              sitekey="YOUR_SITE_KEY" // Replace with your actual site key
              onChange={handleCaptchaChange}
            />
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ContactItem;
