import React, { useState } from 'react';
import '../../App.css';
import Navbar from '../Navbar.js';
import TeamSection from '../TeamSection.js';
import TeamItem from '../TeamItem.js';
import Footer from '../Footer.js';

function Team() {
    const [activeTab, setActiveTab] = useState('board');

    return (
        <>
            
            <TeamSection />
            <div className="tabs">
                <button 
                    className={activeTab === 'board' ? 'active' : ''} 
                    onClick={() => setActiveTab('board')}
                >
                    Board of Directors
                </button>
                <button 
                    className={activeTab === 'experts' ? 'active' : ''} 
                    onClick={() => setActiveTab('experts')}
                >
                    Experts
                </button>
            </div>

            <TeamItem type={activeTab} />

            <Footer />
        </>
    );
}

export default Team;
