import React from 'react';
import './App.css';
import Navbar from './Components/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './Components/pages/Home';
import WhoWeServe from './Components/pages/Services';
import WhyDunham from './Components/pages/WyAdvantage';
import Team from './Components/pages/Team';
import Contact from './Components/pages/Contact';

function App() {
  return (
    <>
    <Router>
      <Navbar />
      <Routes>
        <Route path='/' exact Component={Home} />
        <Route exact path='/WhoWeServe' Component={WhoWeServe} />
        <Route exact path='/WhyDunham' Component={WhyDunham} />
        <Route exact path='/Team' Component={Team} />
        <Route exact path='/Contact' Component={Contact} />
        <Route path='/WhoWeServe/:section' element={<WhoWeServe />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
