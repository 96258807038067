import React from "react";
import { useNavigate } from 'react-router-dom';
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import './Item.css';
import { Link } from 'react-router-dom';
import familyIcon from "../Contents/icons/SVG/family.svg";
import attorneyIcon from "../Contents/icons/SVG/attorney scale.svg";
import wealthIcon from "../Contents/icons/SVG/wealth pie.svg";
import whyImage from "../Contents/Photos/buffalo.png";
import serviceImage from "../Contents/Photos/services.png";
import whoImage from "../Contents/Photos/who.png";
import liveImage from "../Contents/Photos/Live.png";

function Item() {
    
    
    return (
        <div className="items">
            <h1>Who We Serve</h1>
            <div className="info-boxes">
                <div className="info-box">
                    <p>Individuals and Families with Significant Wealth</p>
                    <Link to="/WhoWeServe/individuals" className="learn-more">Learn More</Link>
                </div>
                <div className="info-box">
                    <p>Attorneys, Tax and Business Managers</p>
                    <Link to="/WhoWeServe/attorneys" className="learn-more">Learn More</Link>
                </div>
                <div className="info-box">
                    <p>Multi-Family Offices, Private Wealth Teams and Investment Advisories</p>
                    <Link to="/WhoWeServe/multifamily" className="learn-more">Learn More</Link>
                </div>
                <div className="info-box">
                    <p>Private Family Trust Companies, Single-Family Offices</p>
                    <Link to="/WhoWeServe/private" className="learn-more">Learn More</Link>
                </div>
            </div>
            <div className="items_item">
                <img src={liveImage} alt="Live" className="item-image"/>
                <div className="item-content">
                    <h2 className="item-title">Live Wherever Life is Fullest</h2>
                    <p className="item-description">
                    Dunham Private Trust establishes residency for your assets in tax-friendly jurisdictions 
                    so you and your family can reside wherever life takes you. Our team coordinates your 
                    investment, tax and legal advisors to ensure a seamless implementation of wealth 
                    preservation and legacy building strategies.
                    </p>
                </div>
            </div>
               
            <div className="items_item">
                <div className="item-content">
                    <h2 className="item-title">Wyoming & Nevada</h2>
                    <p className="item-description">
                    We began with Dunham Trust Company in Nevada, setting a high standard in full-service 
                    trust management and earning the confidence of countless families. Building on that 
                    success, we created Dunham Private Trust in Wyoming to offer even more options. 
                    Our bespoke solutions are tailored for high-net-worth families, providing the control, 
                    flexibility, and streamlined processes that truly make a difference.
                    </p>
                </div>
                <img src={whyImage} alt="Wyoming&Nevada" className="item-image"/>
            </div>
               
        </div>
    );
}

export default Item;
