import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import logo from '../Contents/Logo/DPT Logo.svg';

function Footer() {
  return (
    <div className='footer-container'>
      {/* <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Join Our Mailing List
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='first name'
              type='first name'
              placeholder='First name'
            />
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='Your Email'
            />
            <Button buttonStyle='btn--outline'>Subscribe</Button>
          </form>
        </div>
      </section> */}

      <div className='footer-links'>
        <div className='footer-link-wrapper'>
          {/* New Column First */}
          <div className='footer-link-items bold-column'>
            <h3>Dunham Private Trust</h3>
            <Link to='https://www.dunhamtrust.com/Pages/Disclaimers'>DISCLAIMERS</Link>
            <Link to='https://www.dunhamtrust.com/Pages/AML'>ANTI-MONEY LAUNDERING</Link>
            <Link to='https://www.dunhamtrust.com/Pages/PrivacyPolicy'>PRIVACY POLICY</Link>
            <Link to='https://www.dunhamtrust.com/Pages/BCP'>BUSINESS CONTINUITY</Link>
          </div>

          {/* <div className='footer-link-items'>
            <h3>Trust Benefits</h3>
            <Link to='/sign-up'>Significant Trust Benefits</Link>
            <Link to='/'>The Trust Trilogy</Link>
            <Link to='/'>The Wyoming Advantage</Link>
          </div>
          <div className='footer-link-items'>
            <h3>Who We Are</h3>
            <Link to='/'>Leadership Team</Link>
            <Link to='/'>Firm Overview</Link>
            <Link to='/'>Careers</Link>
          </div>
        </div>

        <div className='footer-link-wrapper'>
          <div className='footer-link-items'>
            <h3>Resources</h3>
            <Link to='/'>Resources Library</Link>
            <Link to='/'>Glossary</Link>
          </div> */}
          <div className='footer-link-items'>
            <h3>Contact Us</h3>
            
            <a>Phone: (888) 438-6426</a>
            <a>Fax: (775) 826-7904</a>
          </div>
        </div>
      </div>

      <section className='social-media'>
        <div className='social-media-wrap'>
          <div className='footer-logo'>
            <img src={logo} alt="Logo" />
          </div>
          
          <small className='website-rights'>
            Dunham Private Trust is the Wyoming division of Dunham Trust Company. Trust services offered through Dunham Trust Company, an affiliated Nevada Trust Company.
            <br/><br/>
            ©2024 Dunham Private Trust Company. All Rights Reserved
          </small>
          <div className='social-icons'>
            <Link
              className='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i className='fab fa-youtube' />
            </Link>
            <Link
              className='social-icon-link linkedin'
              to='/'
              target='_blank'
              aria-label='LinkedIn'
            >
              <i className='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
