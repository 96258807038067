import React, { useState, useRef, useEffect } from "react";
import { useParams } from 'react-router-dom';
import './ServiceItem.css';
import individualImage from "../Contents/Photos/1.png";
import attorneyImage from "../Contents/Photos/2.png";
import multifamImage from "../Contents/Photos/3.png";
import privateImage from "../Contents/Photos/4.png";

function useWindowSize() {
    const [windowSize, setWindowSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowSize;
}

function ServiceItem() {
    const { section } = useParams();
    const size = useWindowSize();
    const isMobile = size.width <= 768;

    // Set initial image state
    const [activeImage, setActiveImage] = useState(individualImage);

    // Create refs for each section
    const individualSectionRef = useRef(null);
    const attorneySectionRef = useRef(null);
    const multifamSectionRef = useRef(null);
    const privateSectionRef = useRef(null);

    // Function to get the navbar height dynamically
    const getNavbarHeight = () => {
        const navbar = document.querySelector('.navbar'); // Ensure navbar has this class
        if (navbar) {
            return navbar.offsetHeight;
        }
        return isMobile ? 60 : 80; // Default fallback values for mobile and desktop
    };

    // Scroll function with dynamic offset for navbar
    const scrollWithOffset = (ref) => {
        if (ref && ref.current) {
            const elementPosition = ref.current.getBoundingClientRect().top + window.pageYOffset;
            const navbarHeight = getNavbarHeight();
            const additionalOffset = isMobile ? 20 : 0; // Adjust further if needed
            const offsetPosition = elementPosition - navbarHeight - additionalOffset;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth',
            });
        }
    };

    useEffect(() => {
        if (section === 'individuals') {
            setActiveImage(individualImage);
            scrollWithOffset(individualSectionRef);
        } else if (section === 'attorneys') {
            setActiveImage(attorneyImage);
            scrollWithOffset(attorneySectionRef);
        } else if (section === 'multifamily') {
            setActiveImage(multifamImage);
            scrollWithOffset(multifamSectionRef);
        } else if (section === 'private') {
            setActiveImage(privateImage);
            scrollWithOffset(privateSectionRef);
        }
    }, [section]);

    return (
        <div className="items_item1">
            
            {!isMobile ? (
                <div className="box-container">
                    <div className="item-content">
                    <h2 className="item-title">Who We Serve</h2> {/* Ensure this remains for both mobile and desktop */}
                        <ul className="item-description">
                            <li onMouseEnter={() => setActiveImage(individualImage)} onClick={() => scrollWithOffset(individualSectionRef)}>
                                Individuals and Families with Significant Wealth
                            </li>
                            <li onMouseEnter={() => setActiveImage(attorneyImage)} onClick={() => scrollWithOffset(attorneySectionRef)}>
                                Attorneys, Tax and Business Managers
                            </li>
                            <li onMouseEnter={() => setActiveImage(multifamImage)} onClick={() => scrollWithOffset(multifamSectionRef)}>
                                Multi-Family Offices, Private Wealth Teams and Investment Advisories
                            </li>
                            <li onMouseEnter={() => setActiveImage(privateImage)} onClick={() => scrollWithOffset(privateSectionRef)}>
                                Private Family Trust Companies, Single-Family Offices
                            </li>
                        </ul>
                    </div>
                    <div className="item-image-container">
                        <img src={activeImage} alt="Selected" className="item-image" />
                    </div>
                </div>
            ) : (
                <div className="mobile-options">
                    <h2 className="item-title">Who We Serve</h2> {/* Ensure this remains for both mobile and desktop */}
                    <div className="mobile-option" onClick={() => scrollWithOffset(individualSectionRef)}>
                        <img src={individualImage} alt="Individuals" className="item-image" />
                        <p>Individuals and Families with Significant Wealth</p>
                    </div>
                    <div className="mobile-option" onClick={() => scrollWithOffset(attorneySectionRef)}>
                        <img src={attorneyImage} alt="Attorneys" className="item-image" />
                        <p>Attorneys, Tax and Business Managers</p>
                    </div>
                    <div className="mobile-option" onClick={() => scrollWithOffset(multifamSectionRef)}>
                        <img src={multifamImage} alt="Multi-Family Offices" className="item-image" />
                        <p>Multi-Family Offices, Private Wealth Teams and Investment Advisories</p>
                    </div>
                    <div className="mobile-option" onClick={() => scrollWithOffset(privateSectionRef)}>
                        <img src={privateImage} alt="Private Family Trust" className="item-image" />
                        <p>Private Family Trust Companies, Single-Family Offices</p>
                    </div>
                </div>
            )}

            {/* Sections */}
            <div ref={individualSectionRef} className="wealth-container">
                <h1 className="wealth-title">Individuals and Families with Significant Wealth</h1>
                <p className="wealth-subtitle">Worry Less, Live Fully</p>
                <div className="wealth-cards">
                    <div className="wealth-card">
                        <h2 className="card-title">Bespoke Trust Administration</h2>
                        <p className="card-description">
                            With Dunham, you are so much more than a number. Our in-house legal counsel 
                            and seasoned trust administrators endeavor a thorough review of governing 
                            documents along with your goals and objectives to derive our service offering. 
                            From complex real estate holdings to private credit and closely-held business 
                            interests, your investments are unbound by administrative capacity.
                        </p>
                    </div>

                    <div className="wealth-card">
                        <h2 className="card-title">Vertically Integrated Guidance</h2>
                        <p className="card-description">
                            As a trust company with advisory roots, we are uniquely staffed to anticipate 
                            the needs of families with significant wealth. Our firm consults on the design 
                            while leading the implementation and funding of trusts to accommodate investment, 
                            liquidity events, asset protection, multi-generational planning, and philanthropic 
                            intentions.
                        </p>
                    </div>

                    <div className="wealth-card">
                        <h2 className="card-title">Choose Your Team</h2>
                        <p className="card-description">
                            We work alongside your trusted tax, legal, and investment advisors to ensure seamless 
                            implementation and maintenance of single and multi-jurisdiction strategies. We also 
                            assist clients in identifying gaps in their advisory team and supplement our approach 
                            with a vast network of leading estate, tax, and investment managers.
                        </p>
                    </div>
                </div>
            </div>
            <div ref={attorneySectionRef} className="wealth-container">
                <h1 className="wealth-title">Attorneys, Tax and Business Managers</h1>
                <p className="wealth-subtitle">A Partner in Building Durable Wealth Plans</p>
                <div className="wealth-cards1">
                    <div className="wealth-card1">
                        <h2 className="card-title1">A Collaborative Approach</h2>
                        <p className="card-description1">
                            Leverage our in-house legal expertise and twenty-five years of experience harnessing 
                            the statutory climate in Nevada and Wyoming to assist families with significant wealth 
                            achieve their goals.                        
                        </p>
                    </div>

                    <div className="wealth-card1">
                        <h2 className="card-title1">A Partner in Planning</h2>
                        <p className="card-description1">
                            Our professionals come to us from the drafting, compliance, tax and portfolio management 
                            disciplines and are well-versed in the issues facing lead advisors. Leverage our expertise 
                            during the design phase to ensure a seamless implementation of your trust-centric strategies.
                        </p>
                    </div>

                    <div className="wealth-card1">
                        <h2 className="card-title1">Access to Pro-Trust Situs</h2>
                        <p className="card-description1">
                            Dunham Private Trust serves advisors seeking access to leading multi-generational, asset protection 
                            and liquidity event planning jurisdictions. We have offices in both Nevada and Wyoming as well 
                            as Colorado and provide access to a network of tax, legal professionals across these markets.
                        </p>
                    </div>
                </div>
            </div>
            <div ref={multifamSectionRef} className="wealth-container">
                <h1 className="wealth-title">Multi-Family Offices, Private Wealth Teams and Investment Advisories</h1>
                <p className="wealth-subtitle">Trust Expertise Where it Matters</p>
                <div className="wealth-cards">
                    <div className="wealth-card">
                        <h2 className="card-title">A Holistic Corporate Fiduciary</h2>
                        <p className="card-description">
                            As a corporate trustee, we want the advice teams we work with to feel unbound by 
                            administrative capacity. From closely held business interests to private credit and IP, 
                            we provide stewardship to support the most sophisticated planning techniques.
                        </p>
                    </div>

                    <div className="wealth-card">
                        <h2 className="card-title">A Partner in Planning</h2>
                        <p className="card-description">
                            Our professionals come to us from the drafting, compliance, tax and portfolio management 
                            disciplines and are well-versed in the issues facing lead advisors. Leverage our expertise 
                            during the design phase to ensure a seamless implementation of your trust-centric strategies.
                        </p>
                    </div>

                    <div className="wealth-card">
                        <h2 className="card-title">Access to Pro-Trust Situs</h2>
                        <p className="card-description">
                            Dunham Private Trust serves advisors seeking access to leading multi-generational, asset 
                            protection and liquidity event planning jurisdictions. We have offices in both Nevada and 
                            Wyoming as well as Colorado and provide access to a network of tax, legal professionals across 
                            these markets.
                        </p>
                    </div>
                </div>
            </div>
            <div ref={privateSectionRef} className="wealth-container">
                <h1 className="wealth-title">Private Family Trust Companies, Single-Family Offices</h1>
                <p className="wealth-subtitle">Fiduciary Solutions for Family Trusts</p>
                <div className="wealth-cards1">
                    <div className="wealth-card1">
                        <h2 className="card-title1">Administrative and Operational Support</h2>
                        <p className="card-description1">
                            Dunham Private Trust functions as an outsourced back office solution in the most 
                            favorable jurisdictions for establishing both SFOs and PFTCs. Like an external CFO, 
                            we manage day-to-day operations including trust accounting, record-keeping and financial reporting.                       
                        </p>
                    </div>

                    <div className="wealth-card1">
                        <h2 className="card-title1">Regulatory Compliance and Governance</h2>
                        <p className="card-description1">
                            We work with your trusted legal counsel to draft and implement the operational 
                            and investment policies and procedures that align with the needs of each family. 
                            Our team ensures ongoing maintenance for robust family governance and philanthropic 
                            structures.
                        </p>
                    </div>

                    <div className="wealth-card1">
                        <h2 className="card-title1">Fiduciary Oversight and Risk Management</h2>
                        <p className="card-description1">
                            As a corporate fiduciary. we bring an element of impartiality to the task of adhering 
                            to a family’s goal and objectives and ensuring those goals are effectively communicated 
                            through their trust. We provide an additional layer of investment oversight, tax 
                            reporting and risk management while assessing ongoing asset protection related issues.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ServiceItem;



