import React from 'react';
import '../App.css';
import './ServicesSection.css';
import { Link } from 'react-router-dom';



function ServicesSection() {
  return (
    <div className='hero-container'>
       
      <img src='/photos/images/Who We serve.png' />

      <div className="banner-content" style={{ textAlign: 'left', display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h1 className="banner-title" style={{ color: 'white', width: '60%' }}>Who We Serve</h1>
        <br />
        <p className="banner-paragraph" style={{ color: 'white', width: '60%' }}>
          Dunham’s open-architecture empowers families and their advisory teams to 
          fully-customize their client experience. Whether you're looking to grow your 
          wealth, protect your assets, or plan for your future, we're here to provide 
          the expertise and support you need.
        </p>
        <br />
        {/* <Link to="/about">
            <button className="banner-button">Secure Your Legacy</button>
        </Link> */}
      </div>


    </div>
  );
}

export default ServicesSection;