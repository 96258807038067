import React from 'react';
import '../../App.css';
import Navbar from '../Navbar.js';
import ContactItem from '../ContactItem.js';
import Footer from '../Footer.js';

function Contact() {
    return (
        <>
            <ContactItem/>
            <Footer/>
        </>
    );
}


export default Contact;