import React from 'react';
import '../../App.css'
import Navbar from '../Navbar.js'
import ServiceItem from '../ServiceItem.js';
import ServicesSection from '../ServicesSection.js';
import Footer from '../Footer.js';

function WhoWeServe() {
    return (
        <>
            
            <ServicesSection/>
            <ServiceItem/> 
            <Footer/>
        </>
    );
}


export default WhoWeServe;
  