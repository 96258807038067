import { Route } from 'react-router-dom'
import '../../App.js'
import Navbar from '../Navbar.js'
import Item from '../Item.js';
import HeroSection from '../HeroSection.js';
import Footer from '../Footer.js';


function Home(){
    return (
        <>
            <HeroSection/>
            <Item/> 
            <Footer/>
        </>
    );
}
export default Home;