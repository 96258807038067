import React from 'react';
import '../App.css';
import { Button } from './Button';
import './HeroSection.css';
import { Link } from 'react-router-dom';

function HeroSection() {
  return (
    <div className='hero-container'>
      <img src='/photos/images/home.png' />

      <div className="banner-content">
        <h1 className="banner-title">Dunham Private Trust</h1>
        <br />
        <h3 className='banner-title'>Your Partner in Wealth Preservation and Growth</h3>
        <p className="banner-paragraph">We offer personalized wealth management solutions for high-net-worth individuals and families.</p>
        <br />
        <Link to="/">
            <button className="banner-button">Secure Your Legacy</button>
        </Link>
      </div>
      <div className="hero-footer-text">
        <p>For You. For Them. For Generations to Come.</p>
      </div>
    </div>
  );
}

export default HeroSection;