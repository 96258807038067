import React from 'react';
import '../../App.css'
import Navbar from '../Navbar.js'
import AdvantageSection from '../AdvantageSection.js';
import AdvantageItem from '../AdvantageItem.js';
import Footer from '../Footer.js';

function WhyDunham() {
    return (
        <>
            
            <AdvantageSection/>
            <AdvantageItem/>
            <Footer/>
        </>
    );
}


export default WhyDunham;