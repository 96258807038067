import React, { useState, useEffect, useMemo } from 'react';
import './TeamItem.css';

function TeamItem() {
    const executive = useMemo(() => [
        { name: 'Jeffrey A. Dunham', title: 'FOUNDER, CHAIRMAN & CEO', image: '/photos/Leadership Headshots/jeffrey.png', pdf: '/pdfs/jeff.pdf' },
        { name: 'Ann Rosevear, Esq.', title: 'PRESIDENT & CHIEF TRUST OFFICER', image: '/photos/Leadership Headshots/ann.png', pdf: '/pdfs/ann.pdf' },
        { name: 'Christine Zou, CPA', title: 'CHIEF FINANCIAL OFFICER', image: '/photos/Leadership Headshots/christine.png', pdf: '/pdfs/christine.pdf' },
        { name: 'Helmut Boisch, PMP', title: 'CHIEF OPERATING OFFICER', image: '/photos/Leadership Headshots/helmut.png', pdf: '/pdfs/helmut.pdf' },
        { name: 'Viktoria Palermo, CRCP®, CICCP', title: 'BSA/AML COMPLIANCE OFFICER', image: '/photos/Leadership Headshots/viktoria.png', pdf: '/pdfs/viktoria.pdf' },
        { name: 'Ryan J. Dykmans, CFA', title: 'CHIEF INVESTMENT OFFICER', image: '/photos/Leadership Headshots/ryan.png', pdf: '/pdfs/ryan.pdf' },
    ], []);

    const experts = useMemo(() => [
        { name: 'Ann Rosevear, Esq.', title: 'PRESIDENT & CHIEF TRUST OFFICER', image: '/photos/Leadership Headshots/ann.png', pdf: '/pdfs/ann.pdf' },
        { name: 'David Osgood', title: 'SENIOR TRUST OFFICER', image: '/photos/Leadership Headshots/david.png', pdf: '/pdfs/david.pdf' },
        { name: 'Daniel Sands, CFP®', title: 'DIRECTOR OF TRUST INVESTMENTS', image: '/photos/Leadership Headshots/dan.png', pdf: '/pdfs/dan.pdf' },
        { name: 'Matthew Cooper, MBA, CTFA®', title: 'TRUST OFFICER', image: '/photos/Leadership Headshots/matt.png', pdf: '/pdfs/matt.pdf' },
        { name: 'Charlotte Martinez', title: 'TRUST OFFICER', image: '/photos/Leadership Headshots/charlotte.png', pdf: '/pdfs/charlotte.pdf' },
        { name: 'Bri Abbott', title: 'ADMINISTRATIVE COORDINATOR', image: '/photos/Leadership Headshots/bri.png', pdf: '/pdfs/bri.pdf' },
        { name: 'Nicole Vance, Esq.', title: 'DIRECTOR OF WEALTH PLANNING', image: '/photos/Leadership Headshots/nicole.png', pdf: '/pdfs/nicole.pdf' },
    ], []);

    const boardMembers = useMemo(() => [
        { name: 'Jeffrey A. Dunham', title: 'FOUNDER, CHAIRMAN & CEO', image: '/photos/Leadership Headshots/jeffrey.png', pdf: '/pdfs/jeff.pdf' },
        { name: 'Ann Rosevear, Esq.', title: 'PRESIDENT & CHIEF TRUST OFFICER', image: '/photos/Leadership Headshots/ann.png', pdf: '/pdfs/ann.pdf' },
        { name: 'Denise S. Iverson, CPA', title: 'BOARD MEMBER', image: '/photos/Leadership Headshots/denise.png', pdf: '/pdfs/denise.pdf' },
        { name: 'Joseph Strazzeri, Esq., CEPA', title: 'BOARD MEMBER', image: '/photos/Leadership Headshots/joe.png', pdf: '/pdfs/joe.pdf' },
        { name: 'Marty L. Oblasser, Esq.', title: 'BOARD MEMBER', image: '/photos/Leadership Headshots/marty.png', pdf: '/pdfs/marty.pdf' },
        { name: 'Robert E. Miller, CFA', title: 'BOARD MEMBER', image: '/photos/Leadership Headshots/rob.png', pdf: '/pdfs/robert.pdf' },
        { name: 'Kevin Kessner', title: 'BOARD MEMBER', image: '/photos/Leadership Headshots/kevin.png', pdf: '/pdfs/kevin.pdf' },
    ], []);

    const [type, setType] = useState('executive');

    // const members = type === 'experts' ? experts : type === 'boardMembers' ? boardMembers : executive;
    const members = useMemo(() => {
        return type === 'experts' ? experts : type === 'boardMembers' ? boardMembers : executive;
    }, [type, executive, experts, boardMembers]);

    // Preload images when the component mounts
    useEffect(() => {
        const allImages = [...executive, ...experts, ...boardMembers];
        allImages.forEach((member) => {
            const img = new Image();
            img.src = member.image;
        });
    }, [members, executive, experts, boardMembers]);

    const handleClick = (pdf) => {
        const pdfViewerUrl = `/pdf-viewer.html?pdf=${encodeURIComponent(pdf)}`;
        window.open(pdfViewerUrl, '_blank');
    };

    return (
        <div className="team-container">
            <div className="team-tabs">
                <span
                    className={`team-tab ${type === 'executive' ? 'active' : ''}`}
                    onClick={() => setType('executive')}
                >
                    Executive Leadership
                </span>
                <span
                    className={`team-tab ${type === 'experts' ? 'active' : ''}`}
                    onClick={() => setType('experts')}
                >
                    Our Experts
                </span>
                <span
                    className={`team-tab ${type === 'boardMembers' ? 'active' : ''}`}
                    onClick={() => setType('boardMembers')}
                >
                    Board Of Directors
                </span>
            </div>

            <div className="team-grid">
                {members.map((member, index) => (
                    <div key={index} className="team-item" onClick={() => handleClick(member.pdf)}>
                        <img
                            src={member.image}
                            alt={member.name}
                            className="team-image"
                        />
                        <div className="team-info">
                            <h3>{member.name}</h3>
                            <p>{member.title}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default TeamItem;
