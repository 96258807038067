import React from "react";
import './AdvantageItem.css';
import whyImage from "../Contents/Photos/wynv.png";

function AdvantageItem() {
    return (
        <div className="advantage">
            
            <div className="advantage-item1">
                <div className="advantage-container1">
                    <h2 className="item-title">
                        Why Dunham Private Trust?
                    </h2>
                    <br/>
                    <p className="advantage-description1">
                        The Private Trust division is designed with your family and legacy in mind, offering the 
                        flexibility and regulations that may help your wealth endure for generations.
                    </p>
                    <br />
                    <p className="advantage-description1">
                        When you choose Dunham Private Trust, you’re partnering with an experienced team that 
                        is fully committed to protecting and growing your assets, while taking full advantage of 
                        Wyoming and Nevada's unique benefits.
                    </p>
                </div>

            </div>

            <div className="advantage-box-container">
                <div className="advantage-box">
                    <h4>Tax Efficiency</h4>
                    <p className="advantage-description2">
                        No state income tax<br/>
                        No capital gains tax<br/>
                        No estate tax<br/>
                        No corporate income tax<br/>
                    </p>
                </div>
                <div className="advantage-box">
                    <h4>Limited Liability Company LLC</h4>
                    <p className="advantage-description2">
                        Protects owner's personal assets<br/> 
                        Difficult to sue owner personally <br/>
                        Works even with a single owner <br/>
                        Offers privacy benefits<br/>
                    </p>
                </div>
                <div className="advantage-box">
                    <h4>Domestic Asset Protection Trust</h4>
                    <p className="advantage-description2">
                        Protects assets from creditors except in cases of fraud<br/>
                        Offers some protection from foreign judgments<br/>
                    </p>
                </div>
                
            </div>
            <div className="advantage-box-container">
                <div className="advantage-box">
                    <h4>Family Access</h4>
                    <p className="advantage-description2">
                        Eliminates a trustee's duty to inform a beneficiary 
                        of the existence of a trust, and an accounting thereof.<br/>
                    </p>
                </div>
                <div className="advantage-box">
                    <h4>Silent Trusts</h4>
                    <p className="advantage-description2">
                        Privacy/Silent trusts are particularly important to 
                        parents who are not ready to disclose their wealth to 
                        their children.<br/>
                    </p>
                </div>
                <div className="advantage-box">
                    <h4>Equitable Jurisdictions</h4> <br/>
                    <p className="advantage-description2">
                        Legal cornerstone creating a dual system of enforceable laws<br/>
                    </p>
                </div>
            </div>
            <br/><br/>
            <div className="advantage-different">
                
                <h2 className="advantage-different-title">The Dunham Difference</h2>
                <div className="advantage-box-container">
                <div className="advantage-different-box">
                    <h4>Unparalleled Stewardship</h4>
                    <p className="advantage-description2">
                        Bespoke trust administration for high-net-worth families and 
                        the advisory teams who serve them.
                    </p>
                </div>
                <div className="advantage-different-box">
                    <h4>Your Family Office Solution</h4>
                    <p className="advantage-description2">
                        Our in-house legal counsel and seasoned administrators will work 
                        with your trusted advisors to vertically integrate advice improving 
                        family engagement and governance.
                    </p>
                </div>
                <div className="advantage-different-box">
                    <h4>Investment Advisory</h4>
                    <p className="advantage-description2">
                        Dunham Trust Company has a long track record of providing objective, 
                        goals-based investment guidance customized to support any trust structure.
                    </p>
                </div>
                
            </div>
            
           

                    
                
            </div>
            <br/><br/>
            <div className="items_item">
                <div className="item-content">
                    <h2 className="item-title">Wyoming & Nevada</h2>
                    <p className="item-description">
                    We began with Dunham Trust Company in Nevada, setting a high standard in full-service 
                    trust management and earning the confidence of countless families. Building on that 
                    success, we created Dunham Private Trust in Wyoming to offer even more options. 
                    Our bespoke solutions are tailored for high-net-worth families, providing the control, 
                    flexibility, and streamlined processes that truly make a difference.
                    </p>
                </div>
                <img src={whyImage} alt="Wyoming&Nevada" className="item-image"/>
            </div>    

        </div>
    );
}

export default AdvantageItem;

